import { request } from '../axios'

export const getuserinfo = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/getAuth',
    method: 'get',
    params
  })
// 获取所有设备
export const getAllGoods = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/allGoods',
    method: 'post',
    params
  })
// 获取所有设备分类
export const getAllCategory = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/allCategory',
    method: 'post',
    params
  })
// 获取支付接口
export const pay = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/pay',
    method: 'post',
    params
  })
  //获取退款接口
export const allOrders = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/allOrders',
    method: 'post',
    params
  })
    // //获取状态接口
    export const orderStates = (params) =>
    request({
      url: 'http://ppt.3vppt.com/wx/v1/orderStates',
      method: 'post',
      params
    })
  //获取签名接口
export const getSignPackage = (params) => 
  request({
    url: 'http://ppt.3vppt.com/wx/v1/getSignPackage',
    method: 'post',
    params
  })
