<template>
  <div>
    <van-nav-bar
      title="学生报名"
      @click-left="navbarBack"
      left-arrow
      placeholder
      :fixed="true"
    />
    <div class="topTitleBox">{{ getcourseDetailsData.name }}</div>
    <div class="bodyxunz">
      <div class="topName">巡展简介</div>
      <div class="xuanzInfoBox">
        <div class="eachText">
          <div class="leftNAme">巡展主题:</div>
          <div class="rightInfoText">XR蝴蝶主题巡展</div>
        </div>
        <div class="eachText">
          <div class="leftNAme">巡展内容:</div>
          <div class="rightInfoText">
            <div class="text1">1、蝴蝶标本展陈</div>
            <div class="text1">2、蝴蝶手工活动</div>
            <div class="text1">3、XR互动体验</div>
          </div>
        </div>

        <div class="eachText">
          <div class="leftNAme">单场时长:</div>
          <div class="rightInfoText">{{ ontTime || "90" }}分钟</div>
        </div>

        <div class="eachText">
          <div class="leftNAme">单场人数:</div>
          <div class="rightInfoText">30人（一大一小）</div>
        </div>

        <div class="eachText">
          <div class="leftNAme">巡展时间:</div>
          <div class="rightInfoText">
            <!-- .split(' ')[0] -->
            {{ getcourseDetailsData.time[0]?.datatimevalue1.split(" ")[0] }}
            —
            {{
              getcourseDetailsData.time[
                getcourseDetailsData.time.length - 1
              ]?.datatimevalue2.split(" ")[0]
            }}
          </div>
        </div>

        <div class="eachText">
          <div class="leftNAme">巡展场次:</div>
          <div class="rightInfoText">
            {{ getcourseDetailsData.time.length }}场
          </div>
        </div>
        <div class="eachText">
          <div class="leftNAme">报名者:</div>
          <div class="rightInfoText">
            赠送南山一棵树观景台XR文旅体验项目一次 ，体验时长15分钟
          </div>
        </div>
        <div class="eachText">
          <div class="leftNAme">备注:</div>
          <div class="rightInfoText">
            <div class="text1">景区门票自购</div>
          </div>
        </div>
        <div class="eachText">
          <div class="leftNAme">体验视频:</div>
          <div class="rightInfoText">
            <div class="videobox" id="videoTemp"></div>
          </div>
        </div>
      </div>

      <div class="topName" style="margin-top: 0.4rem">请选择场次</div>

      <div class="selectTimeOrder">
        <div
          class="eachSelectBox"
          @click="selectSign(item, index)"
          v-for="(item, index) in getcourseDetailsData.time"
          :key="index"
          :class="selectInde == index ? 'active' : ''"
        >
          <div style="text-align: center">
            <div>
              {{ item.datatimevalue1 }}
              <!-- </div>
            <div style="margin: 0.1rem 0">至</div>
            <div> -->
              至
              {{ item.datatimevalue2.split(" ")[1] }}
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div>
              已报名：{{ item.countNum ? item.countNum * 2 : 0 }}人 剩余名额：{{
                item.countNum
                  ? remainingPersonnel - item.countNum * 2
                  : remainingPersonnel
              }}人
            </div>
          </div>
        </div>
      </div>

      <div class="activePrice">
        <div style="margin-bottom: 0.2rem">价格</div>
        <!-- <div style="color: red">￥000{{ getcourseDetailsData.price }}</div>
        <div class="moneyDataList" v-for="(item,index) in addMoneyList" :key='index'>
          {{ item.price }}
        </div> -->
        <!-- <van-radio-group v-model="radioMoneyType">
          <van-radio :name="item.price"  v-for="(item,index) in addMoneyList" :key='index'>
            <span  style="color: red">{{item.name}}</span>
          </van-radio>
        </van-radio-group> -->
        <van-radio-group v-model="radioMoneyType">
          <van-cell-group>
            <van-cell
              :title="`${item.price}元 / ${item.name}`"
              v-for="(item, index) in addMoneyList"
              :key="index"
              clickable
              @click="radioMoneyType = item.price"
            >
              <template #right-icon>
                <van-radio checked-color="#ee0a24" :name="item.price" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <van-button type="primary" @click="startSignUp" block
        >开始报名</van-button
      >
    </div>
    <van-overlay :show="showOverlay">
      <div class="wrapper" @click="showOverlay = false">
        <div class="block" @click.stop>
          <div class="overlayTopTitle">报名表</div>
          <van-field
            v-model="username"
            name="学生姓名"
            label="学生姓名"
            placeholder="学生姓名"
            :rules="[{ required: true, message: '请填写学生姓名' }]"
          />
          <van-field
            v-model="result.name"
            is-link
            readonly
            name="picker"
            label="学生年级"
            placeholder="点击选择年级"
            @click="showPickerFun(1)"
          />
          <van-field
            v-model="result1.name"
            is-link
            readonly
            name="picker"
            label="学生班级"
            placeholder="点击选择班级"
            @click="showPickerFun(2)"
          />
          <div class="van-hairline--top van-dialog__footer">
            <button
              @click="showOverlay = false"
              type="button"
              class="van-button van-button--default van-button--large van-dialog__cancel"
            >
              <div class="van-button__content">
                <!----><span class="van-button__text">取消</span
                ><!---->
              </div></button
            ><button
              @click="payFun"
              type="button"
              class="van-button van-button--default van-button--large van-dialog__confirm van-hairline--left"
            >
              <div class="van-button__content">
                <!----><span class="van-button__text">确认并缴费</span
                ><!---->
              </div>
            </button>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        :title="showPickerType == 1 ? '选择年级' : '选择班级'"
        show-toolbar
        :columns="columns"
        value-key="name"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import DPlayer from "dplayer";
import {
  getgradesList,
  getuserEnroll,
  getcourseList,
  getcourseDetails,
  getuserinfo,
  getopenid,
  pay,
  getenroll,
  getwxOpenid,
  getYlWxPlatformOne,
  getYlWxCourse,
  Testupload,
} from "../../api/homeApi/index";

import { generateRandomID } from "../application/outtradeno.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      signTime: this.$store.state.user.signTime,
      radioMoneyType: 0,
      signList: [""],
      selectInde: -1,
      queryData: {},
      getres: null,
      getTime: [],
      getcourseDetailsData: { name: "", price: 0.0, time: [] },
      showOverlay: false,
      showPicker: false,
      columns: [
        // { text: "杭州", value: "Hangzhou" },
      ],
      result: "",
      result1: "",
      username: "",
      showPickerType: 1,
      getenrollData: [],
      remainingPersonnel: 30,
      gradesListData: [],
      timePost: "", //选中的场次
      getUserOpenid: "", //获取到的ipenid
      //   剩余名额为什么要写死，我也不知
      addMoneyList: [],
      ontTime: 0,
      onePage: true,
    };
  },
  mounted() {
    this.queryData = this.$route.query;
    if(this.signTime.length>0){
      if(this.signTime[0].school_id!=this.queryData.school_id){
        this.setSignTime([])
      }
    }
    this.getCouresDetails();
    // 获取班级列表
    this.getgradesListFun();
    // 通过wx返回的code，获取openid
    if (process.env.NODE_ENV == "development") {
      //开发环境
    } else if (process.env.NODE_ENV == "production") {
      this.getwxOpenidFun();
      //生产环境
    } else {
      //本地环境
    }
    // this.getwxOpenidFun();

    this.$nextTick(() => {
      const dp1 = new DPlayer({
        container: document.getElementById("videoTemp"),
        video: {
          url: "http://h5.3vppt.com/videoFile/videoTemp1.mp4",
          pic: "http://h5.3vppt.com/videoFile/videoTemp1.png",
          // url:'https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4'
        },
        preload: "none",
      });
    });
    
    
  },

  methods: {
    ...mapActions("user", ["setSignTime", "setRandomNumber"]),
    // andomNumberFun(index) {
    //   let randomNumber = [],
    //   randomNumberData = this.$store.state.user.randomNumber
    //   console.log('randomNumberData',randomNumberData)
    //   if(randomNumberData.length==0){
    //     for (let i = 0; i < index; i++) {
    //       randomNumber.push(Math.floor(Math.random() * 3) + 1);
    //     }
    //   }
    //   this.setRandomNumber(randomNumber)
    // },
    getwxOpenidFun() {
      try {
        getwxOpenid({ code: this.queryData.code }).then((res) => {
          this.getUserOpenid = res;
          console.log("获取到Openid----------", res); //获取到Openid
        });
      } catch (error) {
        this.$router.push({
          path: "/signUp/XRbutterfly",
          query: this.queryData,
        });
        console.log("获取到Openid 错误", error);
      }
    },
    getgradesListFun() {
      getgradesList({ school_id: this.queryData.school_id }).then((res) => {
        if (JSON.stringify(res.data) != "{}") {
          this.gradesListData = res.data;
        }
        // this.columns = res.data.filter((item) => item.pid == 0);
      });
    },
    payFun() {
      if (this.result == "" || this.result1 == "" || this.username == "") {
        this.$toast("请填写完整信息");
        return;
      }
      console.log("点击了缴费;开始报名", this.result1, "0000000");
      let that = this;

      let getrandomid = generateRandomID(32);

      const getpay = {
        payData: {
          out_trade_no: getrandomid, //订单号
          description: this.getcourseDetailsData.name,
          // openid:  "oTp3w6vqCEyJ4qI6GGkQmG6pr13Y",
          // money:'8000',
          money: Number((this.radioMoneyType * 100).toFixed(0)), //单位分
          // money: Number((this.getcourseDetailsData.price * 100).toFixed(0)), //单位分
          openid: this.getUserOpenid, // "oTp3w6vqCEyJ4qI6GGkQmG6pr13Y",
          goods_id: 0,
        },
      };

      pay(getpay).then((ress) => {
        console.log(ress);
        let payWxinfoData = ress[1];
        window.WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: "wx959c3e8ff427eaeb", //公众号ID，由商户传入
            timeStamp: payWxinfoData.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: payWxinfoData.nonceStr, //随机串
            package: payWxinfoData.prepayId,
            signType: "RSA", //微信签名方式：
            paySign: payWxinfoData.sign,
          },
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              that.$dialog
                .alert({
                  title: "提示",
                  message: "支付成功",
                })
                .then(() => {
                  console.log("进入了支付成功");
                  that.getBaomin();
                });

              // 支付成功 get_brand_wcpay_request:ok
            } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
              that.$toast(`用户取消支付!`);
              console.log("用户取消支付", JSON.stringify(res.err_msg));
              // 支付取消
              // alert("用户取消支付",);
            } else if (res.err_msg == "get_brand_wcpay_request:fail") {
              that.$toast(`支付失败!`);
              console.log("用户取消支付", JSON.stringify(res.err_msg));
              // 支付失败
              // alert("支付失败");
            }
          }
        );
      });

      // this.getBaomin()
    },
    // 开送报名申请
    getBaomin() {
      const tiemtamp = Date.now();
      const create_time = Math.floor(tiemtamp / 1000);
      let newTime = JSON.parse(JSON.stringify(this.timePost)); //选中的场次-时间
      delete newTime.countNum;
      const params = {
        username: this.username, //名字
        time: JSON.stringify(newTime), //选中的场次-时间
        school_id: this.queryData.schoole_id, //学校

        // school_id: this.queryData.id, //通过这个判断的支付的哪个订单

        create_time: create_time, //当前时间
        grades_id: this.result1.id, //选中的班级id
        course_id: this.getcourseDetailsData.id, //报名课程id
      };
      getuserEnroll(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.selectInde = -1;
          this.radioMoneyType = 0;
          this.timePost = ""; //选中的场次
          //清空场次选中
          this.showOverlay = false; //关闭弹窗
          this.getCouresDetails(); //获取剩余报名人数
          this.$toast.success(`恭喜！报名成功！`);
        }
      });
    },
    startSignUp() {
      if (this.getUserOpenid == "") {
        this.$router.push({
          path: "/signUp/XRbutterfly",
          query: this.queryData,
        });
        return;
      }
      if (this.selectInde < 0) {
        this.$toast("请先选择场次！");
        return;
      }
      if (this.radioMoneyType == 0) {
        this.$toast("请先价格套餐！");
        return;
      }
      this.showOverlay = true;
    },
    showPickerFun(type) {
      if (this.result == "" && type == 2) {
        this.$toast("请先选择学生班级！");
        return;
      }
      if (type == 1) {
        this.getClassList(0);
      } else {
        this.getClassList(this.result.id);
      }
      this.result1 = "";
      this.showPickerType = type;
      this.showPicker = true;
    },

    getClassList(id) {
      this.columns = this.gradesListData.filter((item) => item.pid == id);
    },
    onConfirm(ev) {
      console.log(ev, "年纪选中的数据");
      if (ev) {
        if (this.showPickerType == 1) {
          this.result = ev;
        } else {
          this.result1 = ev;
        }
      }
      this.showPicker = false;
    },
    calculateTimeDifference(startTimestamp, endTimestamp) {
      // 将时间戳转换为 Date 对象
      const startDate = new Date(startTimestamp);
      const endDate = new Date(endTimestamp);

      // 计算时间差（以毫秒为单位）
      const timeDifference = endDate.getTime() - startDate.getTime();

      // 将时间差转换为分钟
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));

      return minutesDifference;
    },
    getCouresDetails() {
      let that = this;
      let times = this.$store.state.user.signTime,
        signTime = [];
      getYlWxPlatformOne({ id: this.queryData.id }).then((res) => {
        let shouData = {
          time: JSON.parse(res.resmsg?.time),
          name: res.resmsg.name,
          school_name: res.resmsg.school,
          id: res.resmsg.id,
        };
        try {
          let timenoews = JSON.parse(res.resmsg.time);
          this.ontTime = this.calculateTimeDifference(
            timenoews[0].datatimevalue1,
            timenoews[0].datatimevalue2
          );
        } catch (error) {}
        //获取多价格
        getYlWxCourse({ parent: this.queryData.id }).then((ress) => {
          this.addMoneyList = ress.resmsg;
        });
        // this.andomNumberFun(shouData.time.length)//生成时间列表
        //获取报名人数
        getenroll({ course_id: shouData.id }).then((resRoll) => {
          try {
            for (let i in shouData.time) {
              shouData.time[i].countNum = 0;
              signTime.push({
                school_id: this.queryData.school_id,
                time: shouData.time[i],
                randomNumber: Math.floor(Math.random() * 4),
              });
              if (JSON.stringify(resRoll.data) != "{}") {
                let timeList = resRoll.data;
                Object.entries(timeList).forEach(([key, value]) => {
                  let keyName = JSON.parse(key);
                  // remainingPersonnel 报名总人数
                  if (
                    shouData.time[i].datatimevalue1 == keyName.datatimevalue1
                  ) {
                    let newValue = JSON.parse(JSON.stringify(value));
                    shouData.time[i].countNum = newValue;
                  }
                });
              }
            }
          } catch (error) {
            console.error("error----", error);
          }
          if (times.length == 0) {
            this.setSignTime(signTime);
          }
          this.onePage = false;
          this.getcourseDetailsData = shouData;
          this.setTimeNumber();
        });
      });
    },
    setTimeNumber() {
      let gdd = this.getcourseDetailsData,
        signTime = this.$store.state.user.signTime;
      for (let i in gdd.time) {
        for (let x in signTime) {
          if (this.queryData.school_id == signTime[x].school_id) {
            //学校id匹配
            let allNum = this.remainingPersonnel / 2; // 15人数
            if (gdd.time[i].countNum < 6) {
              //gdd.time[i].countNum未处理的真实报名人数
              //说明当前报名人数小于总数的一半 ，添加假数据
              if (
                gdd.time[i].datatimevalue1 == signTime[x].time.datatimevalue1
              ) {
                gdd.time[i].countNum += 5 + signTime[x].randomNumber;
              }
            }
          }
        }
      }
      this.getcourseDetailsData = gdd;
    },
    setSignEndTime(time, rn) {
      let that = this;
      let times = JSON.parse(JSON.stringify(this.$store.state.user.signTime));
      // console.log(times);
      for (let i in times) {
        if (times[i].time.datatimevalue1 == time.datatimevalue1) {
          console.log(times[i].randomNumber);
          times[i].randomNumber += 1;
          console.log(times[i].randomNumber);
        }
      }
      // this.setSignTime(times);
      // this.setTimeNumber();
      // let sendData = {
      //   school_id: this.queryData.school_id,
      //   time,
      //   randomNumber: rn, //Math.floor(Math.random() * 5),
      // };
      // this.setSignTime(sendData);
    },
    selectSign(item, index) {
      if (item.countNum >= this.remainingPersonnel) {
        this.$toast.fail("请选择其他场次！");
        return;
      }
      this.selectInde = index;
      this.timePost = item;
      console.log("选中的场次", item);
      // this.setSignEndTime(item);
    },
    navbarBack() {
      this.$router.push({
        path: "/signUp/XRbutterfly",
        query: this.$route.query,
      });
    },
    //获取课程列表接口
    getcourseLists() {
      let that = this;
      const params = {
        user_id: this.queryData.schoole_id,
      };
      let dataList = [];
      getcourseList(params).then((res) => {
        let getres = res.data;
        // 这个接口的意义在哪儿
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.van-overlay) {
  z-index: 1001 !important;
}
.activePrice {
  :deep(.van-cell__title) {
    color: red;
    font-weight: bold;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    width: 6.5rem;
    // min-height: 120px;
    background-color: #fff;
    border-radius: 0.2rem;
    overflow: hidden;
    .overlayTopTitle {
      padding: 0.2rem;
      text-align: center;
      font-size: 0.32rem;
      font-weight: bold;
      color: #323233;
    }
  }
}
.bodyxunz {
  padding: 0.2rem;

  .selectTimeOrder {
    font-size: 0.22rem;
    margin-top: 0.2rem;
    .eachSelectBox {
      display: flex;
      justify-content: space-between;
      padding: 0.2rem 0.2rem;
      //   align-items: center;
      background: #e3e3e3;
      border-radius: 0.1rem;
      transition: all 0.2s;
      margin-bottom: 0.2rem;
      &.active {
        background: #d6614f;
        color: #fff;
      }
    }
  }
  :deep(.van-button) {
    margin-top: 1rem;
    border-radius: 0.2rem;
    background-color: #d6614f;
    border: 1px solid #d6614f;
  }
  .activePrice {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    font-size: 0.4rem;
    font-weight: bold;
    margin-top: 0.4rem;
  }
  .xuanzInfoBox {
    margin-top: 0.3rem;
    .eachText {
      margin-top: 0.1rem;
      display: flex;
      // align-items: start;
      justify-content: start;
      font-size: 0.26rem;
      .rightInfoText {
        margin-left: 0.2rem;
        max-width: 4rem;
        .videobox {
          width: 5rem;
          height: 2.7rem;
          border-radius: 0.1rem;
          overflow: hidden;
        }
        .text1 {
          color: rgb(115, 116, 120);
        }
      }
      .leftNAme {
        min-width: 1.1rem;
      }
    }
  }
  .topName {
    color: #737478;
    font-size: 0.5rem;
  }
}
.topTitleBox {
  width: 100%;
  height: 1.6rem;
  background-image: url("@/assets/signUp/11.gif");
  justify-content: space-between;
  text-align: center;
  // line-height: 50px;
  background-size: cover; /* 使图片平铺满整个浏览器（从宽和高的最大需求方面来满足，会使某些部分无法显示在区域中） */
  // background-position: 0px -25px;
  //background-position: center center;//
  // background-attachment: fixed;
  //background-repeat: no-repeat;
  // margin-top: 10px;
  line-height: 2rem;
  font-size: 0.4rem;
  text-align: center;
  justify-content: center;
  color: #737478;
}
</style>
