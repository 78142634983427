<template>
  <div class="XRbutterfly">
    <!-- <van-nav-bar placeholder title="蝴蝶巡展" :fixed="true"/> -->
    <img class="top1Img" src="@/assets/signUp/butterfly/top1.gif" alt="" />
    <div
      style="
        text-align: center;
        font-size: 0.32rem;
        color: rgb(170, 211, 247);
        visibility: visible;
      "
    >
      <p style="visibility: visible">
        <span style="color: rgb(170, 144, 199); visibility: visible"
          >BEAUTIFUL</span
        >
        BUTTERFLY
      </p>
      <div class="girlcont">
        <div class="girlBg">
          <div class="girlBox">
            <img
              class="girlImg"
              src="@/assets/signUp/butterfly/girl.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="text1" style="margin-top: 1rem">
        提到蝴蝶，小朋友们肯定不会陌生，
      </div>
      <div class="text1">自古以来梁祝化蝶的爱情故事，庄周梦蝶的哲学思辨，</div>
      <div class="text1">都给蝴蝶赋予了浪漫和自由的意象。</div>
      <div class="text1" style="margin-top: 0.5rem">
        它是大自然的“花仙子 ”，
      </div>
      <div class="text1">是 “彩色的精灵“，是会飞的花朵”。</div>
      <div class="text1">我们不仅惊叹于它们繁复美丽的花纹，</div>
      <div class="text1">也沉醉于它们优雅曼妙的舞姿。</div>
      <div class="XRbutterflybox girlcont">
        <van-image
          lazy-load
          class="butterflyGif"
          :src="require('@/assets/signUp/butterfly/butterfly.gif')"
          alt=""
        />
      </div>
      <div class="text1">为了丰富儿童的自然科普知识，</div>
      <div class="text1">激发小朋友探索自然奥秘的兴趣，</div>
      <div class="text1">提高动手制作能力，</div>
      <div class="text2">我园联合艾德伦科技、蝶语昆虫博物馆</div>
      <div class="text2">带来火爆全国的XR主题巡展活动，</div>
      <div class="text2">让小朋友们沉浸式进入绚丽多彩的蝴蝶世界，</div>
      <div class="text2">开启一堂关于生命探索的课程。</div>

      <div class="XRbutterflybox girlcont">
        <van-image
          lazy-load
          class="butterflyhigh"
          :src="require('@/assets/signUp/butterfly/butterflyhigh.webp')"
          alt=""
        />
      </div>

      <div class="backSignupBox">
        <div class="xuxianBox">
          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              @click="signUpFun"
              class="signup"
              :src="require('@/assets/signUp/butterfly/signup.gif')"
              alt=""
            />
          </div>
          <div class="text1">目前，蝶语昆虫博物馆已联合山东省博物馆、</div>
          <div class="text1">宁波周尧昆博物馆、大连自然博物馆、</div>
          <div class="text1">天津海洋博物馆、内蒙古自然博物馆</div>
          <div class="text1">
            上海动物园等几十家展馆成功举办蝴蝶创意主题展，
          </div>
          <div class="text1">所到之处火爆异常，</div>
          <div class="text1">深受家长与小朋友们的喜爱。</div>

          <!-- 四宫格图片 -->
          <div class="fourImgBox" style="margin-top: 0.2rem">
            <div class="fourCont">
              <van-image
                lazy-load
                class="courImg"
                :src="require('@/assets/signUp/butterfly/1.webp')"
                alt=""
              />
              <van-image
                lazy-load
                class="courImg"
                :src="require('@/assets/signUp/butterfly/2.webp')"
                alt=""
              />
              <van-image
                lazy-load
                class="courImg"
                :src="require('@/assets/signUp/butterfly/3.webp')"
                alt=""
              />
              <van-image
                lazy-load
                class="courImg"
                :src="require('@/assets/signUp/butterfly/4.webp')"
                alt=""
              />
            </div>
            <div class="borderFour"></div>
            <div class="borderFour1"></div>
          </div>
          <div style="margin-top: 0.3rem" class="text3">
            山东昆博物馆展览现场
          </div>
          <!-- 四宫格图片 -->
          <div class="fourImgBox" style="margin-top: 0rem">
            <div class="fourCont">
              <van-image
                lazy-load
                class="courImg"
                :src="require('@/assets/signUp/butterfly/5.webp')"
                alt=""
              />
              <van-image
                lazy-load
                class="courImg"
                :src="require('@/assets/signUp/butterfly/6.webp')"
                alt=""
              />
              <van-image
                lazy-load
                class="courImg"
                :src="require('@/assets/signUp/butterfly/7.webp')"
                alt=""
              />
              <van-image
                lazy-load
                class="courImg"
                :src="require('@/assets/signUp/butterfly/8.webp')"
                alt=""
              />
            </div>
            <div class="borderFour"></div>
            <div class="borderFour1"></div>
          </div>
          <div style="margin-top: 0.3rem" class="text3">周尧昆博物馆展览</div>

          <div
            class="XRbutterflybox girlcont"
            style="
              padding: 0 0.3rem;
              box-sizing: border-box;
              margin-top: 1.6rem;
            "
          >
            <img
              class="blackimg"
              src="@/assets/signUp/butterfly/blackMan.webp"
              alt=""
            />
          </div>
          <div class="text1">
            今年2月份，
            <span class="special">苹果 vision pro</span>
            正式发售，
          </div>
          <div class="text1">为用户带来全新的XR视觉体验。</div>
          <div class="text1">本次XR巡展采用艾德伦科技最新XR技术，</div>
          <div class="text1">将虚拟和现实完美融合，</div>
          <div class="text1">为小朋友们带来超越想象的前沿体验。</div>
          <div
            class="XRbutterflybox girlcont"
            style="padding: 0 0.3rem; box-sizing: border-box"
          >
            <div id="butterflyVideo1" class="butterflyVideo1"></div>
          </div>

          <div class="text3" style="margin-top: 0.2rem">
            苹果vision pro实景演示
          </div>

          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              @click="signUpFun"
              class="signup"
              :src="require('@/assets/signUp/butterfly/signup.gif')"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="text1" style="margin-top: 0.3rem">
        目前，艾德伦科技XR主题展已落地南湖多彩植物园
      </div>
      <div class="text1">人民小学、星光小学、金童小学、云翠小学、</div>
      <div class="text1">鱼胡路小学、以及银海方舟幼儿园、</div>
      <div class="text1">香山幼儿园、兰花幼儿园和清大幼儿园</div>
      <div class="text1">
        等63所小学、幼儿园等学校组织参观了XR主题展，展览广受师生好评。
      </div>
      <div
        class="XRbutterflybox girlcont"
        style="padding: 0 0.3rem; box-sizing: border-box; margin-top: 0.6rem"
      >
        <div id="butterflyVideo2" class="butterflyVideo2"></div>
      </div>
      <div
        class="XRbutterflybox girlcont"
        style="padding: 0 0.3rem; box-sizing: border-box; margin-top: 0.6rem"
      >
        <div id="butterflyVideo3" class="butterflyVideo2"></div>
      </div>
      <!-- 四宫格图片 -->
      <div class="fourImgBox" style="margin-top: 0.4rem">
        <div class="fourCont">
          <van-image
            lazy-load
            class="courImg"
            :src="require('@/assets/signUp/butterfly/9.webp')"
            alt=""
          />
          <van-image
            lazy-load
            class="courImg"
            :src="require('@/assets/signUp/butterfly/10.webp')"
            alt=""
          />
          <van-image
            lazy-load
            class="courImg"
            :src="require('@/assets/signUp/butterfly/11.webp')"
            alt=""
          />
          <van-image
            lazy-load
            class="courImg"
            :src="require('@/assets/signUp/butterfly/12.webp')"
            alt=""
          />
        </div>
        <div class="borderFour"></div>
        <div class="borderFour1"></div>
      </div>
      <!-- 四宫格图片 -->
      <div class="fourImgBox" style="margin-top: 0rem">
        <div class="fourCont">
          <van-image
            lazy-load
            class="courImg"
            :src="require('@/assets/signUp/butterfly/13.webp')"
            alt=""
          />
          <van-image
            lazy-load
            class="courImg"
            :src="require('@/assets/signUp/butterfly/14.webp')"
            alt=""
          />
          <van-image
            lazy-load
            class="courImg"
            :src="require('@/assets/signUp/butterfly/15.webp')"
            alt=""
          />
          <van-image
            lazy-load
            class="courImg"
            :src="require('@/assets/signUp/butterfly/16.webp')"
            alt=""
          />
        </div>
        <div class="borderFour"></div>
        <div class="borderFour1"></div>
      </div>
      <div class="text3" style="margin-top: 0.3rem">重庆XR创意巡展火爆盛况</div>
      <div class="titleName1" style="margin-top: 0.6rem">
        XR创意蝴蝶巡展介绍
      </div>
      <div class="backSignupBox" style="border-color: rgb(255, 182, 162)">
        <div class="xuxianBox towShare">
          <div class="ExhibitionContentBox">
            <img
              class="butterflyGif1"
              src="@/assets/signUp/butterfly/butterfly1.gif"
              alt=""
            />
            <div class="circularBg">一</div>
            巡展内容
          </div>
          <div class="textListBox">
            <div class="text">
              第一部分 {{ ontTime != 0 ? Math.floor(ontTime / 3) : 15 }}分钟
            </div>
            <div class="contentText">蝴蝶标本展</div>
          </div>
          <div class="textListBox">
            <div class="text">
              第二部分 {{ ontTime != 0 ? Math.floor(ontTime / 3) : 15 }}分钟
            </div>
            <div class="contentText">蝴蝶手工课</div>
          </div>
          <div class="textListBox">
            <div class="text">
              第三部分 {{ ontTime != 0 ? Math.floor(ontTime / 3) : 15 }}分钟
            </div>
            <div class="contentText">XR蝴蝶互动体验</div>
          </div>
          <div class="cintentBox">全程共计{{ ontTime }}分钟</div>

          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              @click="signUpFun"
              class="signup"
              :src="require('@/assets/signUp/butterfly/signup.gif')"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="titleName1" style="margin: 1rem 0 0rem">巡展第一部分</div>
      <div class="XRbutterflybox girlcont">
        <van-image
          lazy-load
          class="butterflyhigh"
          :src="require('@/assets/signUp/butterfly/oneBody.gif')"
          alt=""
        />
        <div class="textTitle">蝴蝶标本展 蝶舞翩翩</div>
      </div>
      <div class="text4">上百件蝴蝶、蝶蛹等标本亮相巡展现场，</div>
      <div class="text4">近距离欣赏优美蝶姿，</div>
      <div class="text4">近距离欣赏优美蝶姿，</div>
      <div class="text4">同时还可以创建属于自己的蝴蝶标本库哦！</div>

      <div class="backSignupBox">
        <div class="xuxianBox">
          <div class="topTitle">
            <div class="leftyuanBox">
              <div class="yuanCont">01</div>
            </div>
            <div class="name">认识蝴蝶</div>
          </div>
          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              class="butterflyhigh"
              :src="require('@/assets/signUp/butterfly/17.webp')"
              alt=""
            />
          </div>
          <div class="text5">
            你知道怎么分辨蝴蝶的<span class="special">性别</span>吗？
          </div>
          <div class="text5">
            你知道蝴蝶的<span class="special">食物</span>有哪些吗？
          </div>
          <div class="text5">
            你知道蝴蝶的 <span class="special">家</span>是什么样的吗？
          </div>
          <div class="text5">
            本次<span class="bold">XR展将带来蝴蝶知识全科谱</span>
          </div>
          <div class="text5">让小朋友们全方位了解蝴蝶。</div>

          <div class="fourImgBox1" style="margin-top: 0rem">
            <img
              class="courImg1"
              src="@/assets/signUp/butterfly/hua.gif"
              alt=""
            />
            <img
              class="courImg2"
              src="@/assets/signUp/butterfly/18.webp"
              alt=""
            />
            <img
              class="courImg3"
              src="@/assets/signUp/butterfly/19.webp"
              alt=""
            />
            <img
              class="courImg4"
              src="@/assets/signUp/butterfly/book.gif"
              alt=""
            />
          </div>

          <div class="topTitle">
            <div class="leftyuanBox">
              <div class="yuanCont">02</div>
            </div>
            <div class="name">不一样的蝴蝶</div>
          </div>

          <div class="text5">
            <span class="bold"> 柑橘凤蝶、黑麦蛱蝶、虎斑蝶……</span>
          </div>
          <div class="text5">
            <span class="bold">十数种珍稀蝴蝶全部展出，</span>
          </div>
          <div class="text5">让小朋友们近距离领略蝴蝶的美。</div>
          <div class="sixBox">
            <div class="sixBoxImg">
              <img
                style="width: 0.6rem; height: 0.6rem; transform: rotate(60deg)"
                class=""
                src="@/assets/signUp/butterfly/butterfly.gif"
                alt=""
              />
            </div>
            <img
              class="sixBoxImg"
              src="@/assets/signUp/butterfly/20.webp"
              alt=""
            />
            <img
              class="sixBoxImg"
              src="@/assets/signUp/butterfly/21.webp"
              alt=""
            />
          </div>
          <div class="sixBox">
            <img
              class="sixBoxImg"
              src="@/assets/signUp/butterfly/22.webp"
              alt=""
            />
            <img
              class="sixBoxImg"
              src="@/assets/signUp/butterfly/23.webp"
              alt=""
            />

            <div class="sixBoxImg" style="align-items: end">
              <img
                style="width: 0.6rem; height: 0.6rem; transform: rotate(0deg)"
                class=""
                src="@/assets/signUp/butterfly/butterfly1.gif"
                alt=""
              />
            </div>
          </div>

          <div class="topTitle">
            <div class="leftyuanBox">
              <div class="yuanCont">03</div>
            </div>
            <div class="name">蝴蝶之最</div>
          </div>

          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              class="butterflyhighwj"
              :src="require('@/assets/signUp/butterfly/24.webp')"
              alt=""
            />
          </div>

          <div class="text5">
            <span class="bold">谁是最美的蝴蝶？</span>
          </div>
          <div class="text5">
            <span class="bold">中国最大的蝴蝶又长什么样？</span>
          </div>
          <div class="text5">
            <span class="bold">飞行最远的蝴蝶能飞多远？</span>
          </div>
          <div class="text5">带领小朋友们了解蝴蝶分类之最，</div>
          <div class="text5">探索奇妙的蝴蝶世界。</div>

          <div class="topTitle">
            <div class="leftyuanBox">
              <div class="yuanCont">04</div>
            </div>
            <div class="name">各国的国蝶</div>
          </div>
          <div class="text5">同国花一样，国蝶也是一个国家的象征。</div>
          <div class="text5">
            <span class="bold">本次巡展将带小朋友了解</span>
          </div>
          <div class="text5">
            <span class="bold">中国、美国、日本等国家的国蝶，</span>
          </div>
          <div class="text5">
            <span class="bold">品味独特的蝴蝶文化底蕴及历史。</span>
          </div>
          <div class="guodie">
            <img class="b1" src="@/assets/signUp/butterfly/25.webp" alt="" />
            <img class="b2" src="@/assets/signUp/butterfly/26.webp" alt="" />
          </div>

          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              @click="signUpFun"
              class="signup"
              :src="require('@/assets/signUp/butterfly/signup.gif')"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="titleName1" style="margin: 1rem 0 0rem">巡展第二部分</div>
      <div class="XRbutterflybox girlcont">
        <van-image
          lazy-load
          class="butterflyhigh"
          :src="require('@/assets/signUp/butterfly/oneBody.gif')"
          alt=""
        />
        <div class="textTitle">蝴蝶手工课堂 溢彩相约</div>
      </div>

      <div class="text4">锻炼孩子的想象力和动手能力，</div>
      <div class="text4">开拓观察能力及创造性思维，培养孩子的自信心。</div>
      <div class="text4">让孩子在开心的氛围中快乐成长。</div>

      <div class="backSignupBox">
        <div class="xuxianBox">
          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              class="butterflyhighwj"
              :src="require('@/assets/signUp/butterfly/27.webp')"
              alt=""
            />
          </div>
          <div class="topTitle" style="padding: 0 0 0.4rem">
            <div class="leftyuanBox">
              <div class="yuanCont">01</div>
            </div>
            <div class="name">蝴蝶绘画</div>
          </div>
          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              class="butterflyhighwj"
              :src="require('@/assets/signUp/butterfly/28.webp')"
              alt=""
            />
          </div>
          <div class="text5">
            <span class="bold">蝴蝶的翅膀是没有颜色的，</span>
          </div>

          <div class="text5">
            <span class="bold">小朋友根据自己喜欢的颜色，</span>
          </div>

          <div class="text5">
            <span class="bold">给蝴蝶穿上彩色的衣服。</span>
          </div>
          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              class="butterflyhighrb"
              :src="require('@/assets/signUp/butterfly/29.webp')"
              alt=""
            />
          </div>
          <div class="topTitle" style="padding: 0 0 0.4rem">
            <div class="leftyuanBox">
              <div class="yuanCont">02</div>
            </div>
            <div class="name">蝴蝶拼装</div>
          </div>
          <div class="text5">
            <span class="bold">将画好的蝴蝶拼装好，放进盒子里，</span>
          </div>
          <div class="text5">
            <span class="bold">打开盒子，蝴蝶就会飞出来了。</span>
          </div>

          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              class="butterflyhighwj"
              :src="require('@/assets/signUp/butterfly/30.webp')"
              alt=""
            />
          </div>

          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              class="butterflyhighwj"
              :src="require('@/assets/signUp/butterfly/31.webp')"
              alt=""
            />
          </div>

          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              class="butterflyhighwj"
              :src="require('@/assets/signUp/butterfly/butterfly2.gif')"
              alt=""
            />
          </div>

          <div class="text3">效果演示</div>

          <div class="XRbutterflybox girlcont">
            <van-image
              lazy-load
              @click="signUpFun"
              class="signup"
              :src="require('@/assets/signUp/butterfly/signup.gif')"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="titleName1" style="margin: 1rem 0 0rem">巡展第三部分</div>
      <div class="XRbutterflybox girlcont">
        <van-image
          lazy-load
          class="butterflyhigh"
          :src="require('@/assets/signUp/butterfly/oneBody.gif')"
          alt=""
        />
        <div class="textTitle">XR创意互动 奇妙世界</div>
      </div>

      <div class="text4">XR沉浸式体验，让孩子与蝴蝶零距离接触，</div>
      <div class="text4">将所学内容真正“消化”成自己的认知，</div>
      <div class="text4">同时锻炼孩子的专注力和协调性。</div>

      <div class="XRbutterflybox girlcont" style="margin-top: 0.6rem">
        <van-image
          lazy-load
          class="butterflyhigh"
          :src="require('@/assets/signUp/butterfly/32.webp')"
          alt=""
        />
      </div>

      <div class="backSignupBox" style="border-color: rgb(255, 182, 162)">
        <div class="xuxianBox">
          <div class="topTitle1" style="margin-top: 0.6rem">
            <img class="hua1" src="@/assets/signUp/butterfly/hua1.gif" alt="" />
            <span> 蝴蝶标本库 </span>
          </div>

          <div class="text6" style="margin-top: 0.2rem">
            天空中出现各种各样的蝴蝶，小朋友伸出手指，保持不动，蝴蝶就会停在小朋友的手上，保持一定时间，蝴蝶就被捕获啦，看谁捕获的蝴蝶多,捕获蝴蝶最多的前三名还有惊喜奖品哦。
          </div>

          <div
            class="XRbutterflybox girlcont"
            style="
              padding: 0 0.2rem;
              box-sizing: border-box;
              margin-top: 0.6rem;
            "
          >
            <div id="butterflyVideo4" class="butterflyVideo2"></div>
          </div>
          <div class="XRbutterflybox gifTwo" style="margin-top: 0.4rem">
            <van-image
              lazy-load
              class="gifTwoImg"
              :src="require('@/assets/signUp/butterfly/xrb1.gif')"
              alt=""
            />
            <van-image
              lazy-load
              class="gifTwoImg"
              :src="require('@/assets/signUp/butterfly/xrb2.gif')"
              alt=""
            />
          </div>
          <div class="text3" style="margin-top: 0rem">XR蝴蝶互动演示</div>
          <section
            style="
              justify-content: flex-start;
              display: flex;
              flex-flow: row;
              margin-top: 0.08rem;
              margin-bottom: 0.08rem;
              padding: 0 0.2rem;
            "
          >
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 0%;
                height: auto;
                padding-right: 4px;
                line-height: 0;
              "
            >
              <section style="text-align: center">
                <section
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 20px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(219, 197, 231);
                  "
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 0%;
                height: auto;
                padding-right: 4px;
                line-height: 0;
              "
            >
              <section style="text-align: center">
                <section
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 20px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(219, 197, 231);
                  "
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 0%;
                height: auto;
                padding-right: 4px;
                line-height: 0;
              "
            >
              <section style="text-align: center">
                <section
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 20px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(219, 197, 231);
                  "
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 0%;
                height: auto;
                padding-right: 4px;
                line-height: 0;
              "
            >
              <section style="text-align: center">
                <section
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 20px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(219, 197, 231);
                  "
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 0%;
                height: auto;
                padding-right: 4px;
                line-height: 0;
              "
            >
              <section style="text-align: center">
                <section
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 20px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(219, 197, 231);
                  "
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 0%;
                height: auto;
                padding-right: 4px;
                line-height: 0;
              "
            >
              <section style="text-align: center">
                <section
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 20px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(219, 197, 231);
                  "
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 0%;
                height: auto;
                padding-right: 4px;
                line-height: 0;
              "
            >
              <section style="text-align: center">
                <section
                  style="
                    display: inline-block;
                    width: 4px;
                    height: 20px;
                    vertical-align: top;
                    overflow: hidden;
                    background-color: rgb(219, 197, 231);
                  "
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 100 100 0%;
                height: auto;
                padding-left: 10px;
              "
            >
              <section style="margin-top: 0.5em; margin-bottom: 0.5em">
                <section
                  style="background-color: rgb(219, 197, 231); height: 1px"
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                flex: 0 0 0%;
                height: auto;
                align-self: center;
                z-index: auto;
                line-height: 0;
              "
            >
              <section style="">
                <section
                  style="
                    display: inline-block;
                    width: 7px;
                    height: 7px;
                    vertical-align: top;
                    overflow: hidden;
                    border-width: 0px;
                    border-radius: 112px;
                    border-style: none;
                    border-color: rgb(62, 62, 62);
                    background-color: rgb(219, 197, 231);
                  "
                >
                  <svg
                    viewBox="0 0 1 1"
                    style="
                      float: left;
                      line-height: 0;
                      width: 0;
                      vertical-align: top;
                    "
                  ></svg>
                </section>
              </section>
            </section>
          </section>
          <div class="topTitle1" style="margin-top: 0.4rem">
            <img class="hua1" src="@/assets/signUp/butterfly/hua1.gif" alt="" />
            <span>我来遛蝴蝶</span>
          </div>
          <div class="text6" style="margin-top: 0.2rem">
            当小朋友轻轻地移动手指，蝴蝶就会跟着手指飞，随着时间推移，蝴蝶会越来越多，就像遛狗一样，看谁遛的蝴蝶最多。
            <div class="XRbutterflybox girlcont" style="margin-top: 0.2rem">
              <van-image
                lazy-load
                class="butterflyhigh"
                :src="require('@/assets/signUp/butterfly/xrb3.gif')"
                alt=""
              />
            </div>
            <section
              style="
                justify-content: flex-start;
                display: flex;
                flex-flow: row;
                margin-top: 0.7rem;
                margin-bottom: 0.7rem;
                padding: 0 0.2rem;
              "
            >
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  align-self: center;
                  flex: 0 0 0%;
                  height: auto;
                  padding-right: 4px;
                  line-height: 0;
                "
              >
                <section style="text-align: center">
                  <section
                    style="
                      display: inline-block;
                      width: 4px;
                      height: 20px;
                      vertical-align: top;
                      overflow: hidden;
                      background-color: rgb(219, 197, 231);
                    "
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  align-self: center;
                  flex: 0 0 0%;
                  height: auto;
                  padding-right: 4px;
                  line-height: 0;
                "
              >
                <section style="text-align: center">
                  <section
                    style="
                      display: inline-block;
                      width: 4px;
                      height: 20px;
                      vertical-align: top;
                      overflow: hidden;
                      background-color: rgb(219, 197, 231);
                    "
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  align-self: center;
                  flex: 0 0 0%;
                  height: auto;
                  padding-right: 4px;
                  line-height: 0;
                "
              >
                <section style="text-align: center">
                  <section
                    style="
                      display: inline-block;
                      width: 4px;
                      height: 20px;
                      vertical-align: top;
                      overflow: hidden;
                      background-color: rgb(219, 197, 231);
                    "
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  align-self: center;
                  flex: 0 0 0%;
                  height: auto;
                  padding-right: 4px;
                  line-height: 0;
                "
              >
                <section style="text-align: center">
                  <section
                    style="
                      display: inline-block;
                      width: 4px;
                      height: 20px;
                      vertical-align: top;
                      overflow: hidden;
                      background-color: rgb(219, 197, 231);
                    "
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  align-self: center;
                  flex: 0 0 0%;
                  height: auto;
                  padding-right: 4px;
                  line-height: 0;
                "
              >
                <section style="text-align: center">
                  <section
                    style="
                      display: inline-block;
                      width: 4px;
                      height: 20px;
                      vertical-align: top;
                      overflow: hidden;
                      background-color: rgb(219, 197, 231);
                    "
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  align-self: center;
                  flex: 0 0 0%;
                  height: auto;
                  padding-right: 4px;
                  line-height: 0;
                "
              >
                <section style="text-align: center">
                  <section
                    style="
                      display: inline-block;
                      width: 4px;
                      height: 20px;
                      vertical-align: top;
                      overflow: hidden;
                      background-color: rgb(219, 197, 231);
                    "
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  align-self: center;
                  flex: 0 0 0%;
                  height: auto;
                  padding-right: 4px;
                  line-height: 0;
                "
              >
                <section style="text-align: center">
                  <section
                    style="
                      display: inline-block;
                      width: 4px;
                      height: 20px;
                      vertical-align: top;
                      overflow: hidden;
                      background-color: rgb(219, 197, 231);
                    "
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  align-self: center;
                  flex: 100 100 0%;
                  height: auto;
                  padding-left: 10px;
                "
              >
                <section style="margin-top: 0.5em; margin-bottom: 0.5em">
                  <section
                    style="background-color: rgb(219, 197, 231); height: 1px"
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
              <section
                style="
                  display: inline-block;
                  vertical-align: middle;
                  width: auto;
                  flex: 0 0 0%;
                  height: auto;
                  align-self: center;
                  z-index: auto;
                  line-height: 0;
                "
              >
                <section style="">
                  <section
                    style="
                      display: inline-block;
                      width: 7px;
                      height: 7px;
                      vertical-align: top;
                      overflow: hidden;
                      border-width: 0px;
                      border-radius: 112px;
                      border-style: none;
                      border-color: rgb(62, 62, 62);
                      background-color: rgb(219, 197, 231);
                    "
                  >
                    <svg
                      viewBox="0 0 1 1"
                      style="
                        float: left;
                        line-height: 0;
                        width: 0;
                        vertical-align: top;
                      "
                    ></svg>
                  </section>
                </section>
              </section>
            </section>
          </div>
          <div class="giftheer">
            <img
              class="gifposition"
              src="@/assets/signUp/butterfly/xrb4.gif"
              alt=""
            />
            <img
              class="gifposition1"
              src="@/assets/signUp/butterfly/xrb5.gif"
              alt=""
            />
            <img
              style="width: 1.8rem; top: 2rem"
              class="gifposition2"
              src="@/assets/signUp/butterfly/qq.gif"
              alt=""
            />
          </div>
          <div class="text7">
            <span class="italicText">第一名：</span>
          </div>
          <div class="text7">电动蝴蝶翅膀/恐龙翅膀</div>
          <div class="text7">
            <span class="italicText">第二名：</span>
          </div>
          <div class="text7">蝴蝶标本相框</div>
          <div class="text7">
            <span class="italicText">第三名：</span>
          </div>
          <div class="text7" style="padding-bottom: 0.2rem">蝴蝶彩绘盒</div>
        </div>
      </div>
      <div class="twoBox">
        <div class="ExhibitionContentBox">
          <img
            class="butterflyGif1"
            src="@/assets/signUp/butterfly/butterfly1.gif"
            alt=""
          />
          <div class="circularBg" style="background: rgb(170, 144, 199)">
            二
          </div>
          活动说明
        </div>
      </div>
      <div
        class="backSignupBox"
        style="border-color: rgb(255, 182, 162); margin-top: 0.2rem"
      >
        <div class="xuxianBox">
          <div class="XRbutterflybox girlcont" style="margin-top: 0.6rem">
            <van-image
              lazy-load
              class="butterflyhighwj"
              :src="require('@/assets/signUp/butterfly/33.webp')"
              alt=""
            />
          </div>
          <div class="activeCont">
            <div class="topActiveText">活动<span>时间</span></div>
            <div class="activeInfo">
              {{ getcourseDetailsData.time[0]?.datatimevalue1 }}
              —
              {{
                getcourseDetailsData.time[getcourseDetailsData.time.length - 1]
                  ?.datatimevalue2
              }}
            </div>
          </div>
          <div class="activeCont">
            <div class="topActiveText">活动<span>地点</span></div>
            <div class="activeInfo">{{ getcourseDetailsData.school_name }}</div>
          </div>
          <div class="activeCont">
            <div class="topActiveText">活动<span>费用</span></div>

            <div class="activeInfo">
              <span
                style="margin-right: 0.1rem"
                v-for="(item, index) in addMoneyList"
                :key="index"
                >{{ item.name }}/{{ item.price }}元
              </span>
            </div>
            <!-- <div class="activeInfo">{{ getcourseDetailsData.price }}元/组</div> -->
          </div>
          <div class="activeCont">
            <div class="topActiveText">
              活动<span>
                <!-- 咨询 -->
                备注</span
              >
            </div>
            <!-- <div class="activeInfo">张老师 1388888888</div> -->
            <div class="activeInfo" style="padding: 0 0.2rem">
              报名者:赠送南山一棵树观景台XR文旅体验项目一次 ，体验时长15分钟
            </div>
            <div class="activeInfo">
              <!-- 备注: -->
              景区门票需自购
            </div>
            <div id="butterflyVideo6" class="butterflyVideo2"></div>
          </div>

          <div class="XRbutterflybox girlcont" style="margin-top: 0rem">
            <van-image
              lazy-load
              @click="signUpFun"
              class="signup"
              style="width: 6rem"
              :src="require('@/assets/signUp/butterfly/signup.gif')"
              alt=""
            />
          </div>

          <div class="text8">
            <div class="kpTitle">
              <div class="kptext">小科普</div>
              <div class="kptext1">什么是XR技术?</div>
            </div>
            <div style="margin-top: 0.3rem">
              XR，即 Extended Reality（扩展现实技术）。XR 是
              VR（虚拟现实技术）/AR（增强现实技术）/MR（混合现实技术）等相关技术的统称，它可以让用户在现实中创建出完全沉浸式的场景，再通过虚拟现实融合技术，将用户置于玄幻神奇的虚拟世界中，在视频画面中营造空间感，达到看上去的立体效果，摆脱3D眼镜的束缚，获得3D观看的视觉效果和体验，置身其间，如入未来之境。呈现出真实立体的三维空间交互。
            </div>

            <div
              class="XRbutterflybox girlcont"
              style="padding: 0 0rem; box-sizing: border-box; margin-top: 0rem"
            >
              <div id="butterflyVideo5" class="butterflyVideo2"></div>
            </div>
            <div class="text3">XR演示</div>
            <div>
              XR技术已广泛运用于军事、天文、地理、科技、历史、恐龙、生物、教育研学等领域。
            </div>
          </div>
        </div>
      </div>

      <img
        class="top1Img"
        style="transform: rotate(180deg)"
        src="@/assets/signUp/butterfly/bottom1.gif"
        alt=""
      />
    </div>

    <div
      class="positionRightSignup"
      @click="signUpFun"
      style="margin-top: 0rem"
    >
      <!-- <van-image lazy-load
        class="signup"
        style="width: 6rem"
        :src="require('@/assets/signUp/butterfly/signup.gif')"
        alt=""
      /> -->
      <div>点击</div>
      <div>报名</div>
    </div>
  </div>
</template>

<script>
import DPlayer from "dplayer";
import urlencode from "urlencode";

import {
  getcourseDetails,
  getenroll,
  getYlWxPlatformOne,
  getYlWxCourse,
} from "../../api/homeApi/index";
import wx from "weixin-js-sdk";
import { wechatSharefund } from "../../api/wechatSharefund";

function isWeixinBrowser() {
  return /MicroMessenger/i.test(navigator.userAgent);
}
export default {
  data() {
    return {
      addMoneyList: [],
      queryData: {},
      getcourseDetailsData: { name: "", price: 0.0, time: [], school_name: "" },
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        muted: false, // 是否静音
        loop: false, // 是否循环播放
        language: "zh-CN",
        aspectRatio: "16:9", // 宽高比
        fluid: true, // 自适应宽高
        sources: [
          {
            type: "video/mp4", // 视频格式
            src: "http://h5.3vppt.com/videoFile/butterflyVideo1.mp4", // 视频URL
          },
        ],
        poster: require("../../../../assets/signUp/video/videoBg1.jpg"), // 封面图
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 不支持提示信息
      },
      ontTime: 0,
    };
  },
  async mounted() {
    let that = this;
    this.queryData = this.$route.query;

    this.$nextTick(() => {
      const dp1 = new DPlayer({
        container: document.getElementById("butterflyVideo1"),
        video: {
          url: "http://h5.3vppt.com/videoFile/butterflyVideo1.mp4",
          pic: require("../../../../assets/signUp/video/videoBg1.jpg"),
        },
        preload: "none",
      });
      const dp2 = new DPlayer({
        container: document.getElementById("butterflyVideo2"),
        video: {
          url: "http://h5.3vppt.com/videoFile/butterflyVideo2.mp4",
          pic: require("../../../../assets/signUp/video/videoBg2.jpg"),
        },
        preload: "none",
      });
      const dp3 = new DPlayer({
        container: document.getElementById("butterflyVideo3"),
        video: {
          url: "http://h5.3vppt.com/videoFile/butterflyVideo3.mp4",
          pic: require("../../../../assets/signUp/video/videoBg3.jpg"),
        },
        preload: "none",
      });
      const dp4 = new DPlayer({
        container: document.getElementById("butterflyVideo4"),
        video: {
          url: "http://h5.3vppt.com/videoFile/xrbVideo.mp4",
          pic: require("../../../../assets/signUp/video/videoBg4.jpg"),
        },
        preload: "none",
      });
      const dp5 = new DPlayer({
        container: document.getElementById("butterflyVideo5"),
        video: {
          url: "http://h5.3vppt.com/videoFile/mateXr.mp4",
          pic: require("../../../../assets/signUp/video/videoBg5.jpg"),
        },
        preload: "none",
      });
      const dp6 = new DPlayer({
        container: document.getElementById("butterflyVideo6"),
        video: {
          url: "http://h5.3vppt.com/videoFile/videoTemp1.mp4",
          pic: "http://h5.3vppt.com/videoFile/videoTemp1.png",
        },
        preload: "none",
      });
    });
    await this.getCouresDetails();
  },
  methods: {
    calculateTimeDifference(startTimestamp, endTimestamp) {
      // 将时间戳转换为 Date 对象
      const startDate = new Date(startTimestamp);
      const endDate = new Date(endTimestamp);

      // 计算时间差（以毫秒为单位）
      const timeDifference = endDate.getTime() - startDate.getTime();

      // 将时间差转换为分钟
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));

      return minutesDifference;
    },
    async setwxData() {
      let that = this;
      // 使用示例
      if (isWeixinBrowser()) {
        await wechatSharefund();
        console.log("当前页面在微信内置浏览器中打开", location.href);
        wx.ready(function (res) {
          console.log("成功配置分享");
          wx.updateAppMessageShareData({
            title: that.getcourseDetailsData?.name || "蝴蝶巡展!",
            desc: "点击立即报名", // 分享描述
            link: location.href,
            imgUrl: "http://h5.3vppt.com/videoFile/logo.jpg", // 分享图标
            success: function () {
              // 设置成功
            },
          });
        });
        wx.error(function (res) {
          console.log("分享失败配置", res);
        });
      }
    },
    getCouresDetails() {
      let that = this;
      getYlWxPlatformOne({ id: this.queryData.id }).then((res) => {
        console.log(res.resmsg.time, "------------- ");
        let shouData = {
          time: JSON.parse(res.resmsg?.time),
          name: res.resmsg.name,
          school_name: res.resmsg.school,
        };

        try {
          let timenoews = JSON.parse(res.resmsg.time);
          this.ontTime = this.calculateTimeDifference(
            timenoews[0].datatimevalue1,
            timenoews[0].datatimevalue2
          );
          // console.log(this.ontTime, "------------- ");
        } catch (error) {}
        this.getcourseDetailsData = shouData;
        this.setwxData();
        console.log(shouData);
        getYlWxCourse({ parent: this.queryData.id }).then((ress) => {
          this.addMoneyList = ress.resmsg;
        });
      });
      return;
      getcourseDetails({
        id: this.queryData.id,
        school_id: this.queryData.school_id,
      }).then((res) => {
        // 处理时间
        let shouData = res.data[0];
        shouData.time = JSON.parse(res.data[0].time);
        document.title = shouData.name;
        //获取报名人数
        getenroll({ course_id: shouData.id }).then((resRoll) => {
          if (JSON.stringify(resRoll.data) != "{}") {
            try {
              let timeList = resRoll.data;
              for (let i in shouData.time) {
                Object.entries(timeList).forEach(([key, value]) => {
                  let keyName = JSON.parse(key);
                  console.log(
                    `Key: ${key}, Value: ${value}------`,
                    keyName.datatimevalue1
                  );
                  if (
                    shouData.time[i].datatimevalue1 == keyName.datatimevalue1 &&
                    shouData.time[i].datatimevalue2 == keyName.datatimevalue2
                  ) {
                    shouData.time[i].countNum = value;
                  }
                });
              }
            } catch (error) {}
          }
          this.getcourseDetailsData = shouData;
          console.log(this.getcourseDetailsData, 99999999999);
        });
      });
    },
    signUpFun() {
      this.souquan();
      // this.gotoWxEmpower();
      return;
      let { id, loginid, school_id, schoole_id } = this.$route.query;
      // 点击了报名按钮
      this.$router.push({
        path: "/signUp/application",
        query: {
          id: id,
          schoole_id: schoole_id,
          loginid: loginid,
          school_id: school_id,
        },
      });
    },

    souquan() {
      let { id, loginid, school_id, schoole_id } = this.$route.query;
      if (id == "" || loginid == "" || school_id == "" || schoole_id == "") {
        this.$toast.fail("系统错误，请重新进入！");
        return;
      }
      let urlurl = urlencode(
        "http://h5.3vppt.com/301.html" +
          "?id=" +
          id +
          "&schoole_id=" +
          schoole_id +
          "&loginid=" +
          loginid +
          "&school_id=" +
          school_id
      );
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx959c3e8ff427eaeb&redirect_uri=" +
        urlurl +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      // scope = snsapi_base;
      // scope = ssnsapi_userinfo;
    },
    gotoWxEmpower() {
      let { id, loginid, school_id, schoole_id } = this.$route.query;
      // 点击了报名按钮
      this.$router.push({
        path: "/signUp/application",
        query: {
          id: id,
          schoole_id: schoole_id,
          loginid: loginid,
          school_id: school_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.XRbutterfly {
  background: #fff3f5;
  min-height: 100vh;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: 31.8538%;
  background-attachment: scroll;
  font-size: 0.32rem;
  line-height: 2.1;
  letter-spacing: 1px;
  background-image: url(@/assets/signUp/butterfly/bg.png);
  visibility: visible;
  margin-bottom: 0px;
  margin: 0 0.3rem;
  // box-sizing: border-box;
  .fourImgBox1 {
    width: 100%;
    height: 4rem;
    position: relative;
    top: 0.2rem;
    box-sizing: border-box;
    .courImg4 {
      position: absolute;
      width: 2rem;
      height: auto;
      z-index: 4;
      right: 0rem;
      bottom: 0.5rem;
      transform: rotate(80deg);
    }
    .courImg3 {
      position: absolute;
      width: 3.4rem;
      height: auto;
      left: 0.2rem;
      bottom: 0;
      z-index: 3;
      border: 0.2rem solid rgb(198, 235, 251);
    }
    .courImg1 {
      position: absolute;
      width: 2rem;
      height: auto;
      left: 0.2rem;
      top: 0;
      z-index: 1;
    }
    .courImg2 {
      position: absolute;
      width: 2.6rem;
      height: auto;
      z-index: 2;
      right: 0.2rem;
      top: 0;
    }
  }
  .fourImgBox {
    width: 100%;
    position: relative;
    .borderFour {
      position: absolute;
      z-index: 9;
      width: 89%;
      height: 91%;
      border: 1px solid rgb(62, 62, 62);
      top: 7%;
      left: 6%;
      background: #fff;
    }
    .borderFour1 {
      position: absolute;
      z-index: 8;
      width: 89%;
      height: 92%;
      border: 1px solid rgb(62, 62, 62);
      top: 9%;
      left: 8%;
      background: #fff;
    }
    .fourCont {
      position: relative;
      z-index: 10;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; /* 水平方向上均匀分布 */
      align-items: stretch; /* 垂直方向上拉伸 */
      width: 96%; /* 或者你想要的任何宽度 */
      padding: 0.2rem;
      box-sizing: border-box;

      .courImg {
        border: 1px solid rgb(62, 62, 62);
        flex: 0 0 calc(50% - 0.1rem); /* 假设有10px的间隔，则每个项目占据一半减去间隔 */
        margin-bottom: 05px; /* 底部间隔 */
        background-color: #ddd;
        text-align: center;
        width: 49%;
        height: auto;
        color: #333;
        box-sizing: border-box; /* 包括padding和border在设定的宽度内 */
      }
      .courImg:nth-child(2n + 1) {
        margin-right: 0.05rem;
      }

      /* 最后一行的元素不需要底部间隔 */
      .courImg:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
  }
  .twoBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.8rem;
  }
  .activeCont {
    width: 100%;
    .activeInfo {
      font-size: 0.26rem;
      color: rgb(83, 88, 119);
      margin: 0.2rem 0;
    }
    .topActiveText {
      border-bottom: 1px solid rgb(170, 144, 199);
      display: inline-block;
      font-size: 18px;
      // padding-right: 5px;
      padding-left: 5px;
      line-height: 1;
      color: rgb(83, 88, 119);
      span {
        background-color: rgb(170, 144, 199);
        display: inline-block;
        font-size: 18px;
        line-height: 1;
        padding: 5px 5px 1px;
        color: rgb(255, 255, 255);
        margin-left: 5px;
      }
    }
  }
  .backSignupBox {
    width: 92%;
    margin-left: 4%;
    background: #fff;
    border: 0.04rem solid rgb(219, 197, 231);
    padding: 0.1rem;
    border-radius: 0.2rem;
    box-sizing: border-box;
    .guodie {
      position: relative;
      width: 100%;
      height: 7.8rem;
      margin-top: 0.2rem;
      .b1 {
        position: absolute;
        width: 64%;
        height: auto;
        top: 0;
        right: 0.2rem;
        border: 0.14rem solid rgb(219, 197, 231);
      }
      .b2 {
        position: absolute;
        width: 64%;
        height: auto;
        top: 3.2rem;
        left: 0.2rem;
        border: 0.14rem solid rgb(219, 197, 231);
      }
    }
    .giftheer {
      width: 100%;
      position: relative;
      height: 4.6rem;
      .gifposition1 {
        position: absolute;
        right: 0.2rem;
        top: 0.8rem;
        height: 3.5rem;
        width: auto;
        z-index: 1;
      }
      .gifposition,
      .gifposition2 {
        position: absolute;
        left: 0.2rem;
        top: 0.2rem;
        border-radius: 50%;
        height: auto;
        width: 2.4rem;
        z-index: 1;
      }
    }
    .topTitle1 {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 0.02rem;

      .hua1 {
        width: 0.6rem;
        height: auto;
      }
    }
    .topTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.4rem 0;
      .name {
        font-size: 0.32rem;
        font-weight: bold;
        color: rgb(107, 97, 167);
        border-bottom: 1px dashed rgb(194, 230, 252);
        margin-left: 0.2rem;
      }
      .leftyuanBox {
        width: 1rem;
        height: 1rem;
        // border: 1px solid red;
        position: relative;
        .yuanCont {
          width: 0.8rem;
          height: 0.8rem;
          background: rgb(170, 144, 199);
          border-radius: 50%;
          position: absolute;
          top: 0.1rem;
          left: 0.1rem;
          font-weight: bold;
          color: #fff;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.3rem;
          height: 0.3rem;
          background: rgb(213, 243, 253);
          border-radius: 50%;
        }
        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0.16rem;
          height: 0.16rem;
          background: rgb(253, 218, 4);
          border-radius: 50%;
        }
      }
    }
    .xuxianBox {
      border-radius: 0.2rem;
      border: 0.04rem dashed rgb(198, 235, 251);
    }

    .sixBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .sixBoxImg {
        flex: 0 0 calc(30% - 0.05rem);
        width: 28%;
        margin-right: 0.1rem;
        margin-bottom: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .towShare {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      padding: 0.8rem 0 0;
      .textListBox {
        margin-top: 0.4rem;
        .text {
          color: rgb(170, 144, 199);
          padding: 0 0.1rem;
          text-align: center;
          border-bottom: 1px solid rgb(170, 144, 199);
        }
        .contentText {
          color: rgb(255, 104, 39);
          margin-top: 0.1rem;
          font-weight: bold;
          font-size: 0.36rem;
        }
      }
      .cintentBox {
        text-align: center;
        color: rgb(170, 144, 199);
        font-size: 0.28rem;
        font-weight: bold;
        margin-top: 0.2rem;
      }
    }
  }
  .ExhibitionContentBox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.32rem;
    color: rgb(107, 97, 167);
    font-weight: bold;
    border: 1px dashed rgb(219, 197, 231);
    padding: 0.2rem 0.3rem;
    position: relative;
    .butterflyGif1 {
      position: absolute;
      right: 0rem;
      top: -0.5rem;
      width: 1rem;
      height: auto;
    }
    .circularBg {
      width: 0.4rem;
      height: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(107, 97, 167);
      border-radius: 50%;
      color: #fff;
      margin-right: 0.2rem;
      font-size: 0.2rem;
    }
  }
  .XRbutterflybox {
    margin: 0.2rem 0;
    position: relative;
    .textTitle {
      position: absolute;
      width: 100%;
      top: 1.8rem;
      font-size: 0.34rem;
      font-weight: bold;
      color: rgb(107, 97, 167);
    }
    .butterflyVideo1 {
      width: 100%;
      height: auto;
      border-radius: 0.06rem;
    }
    .butterflyVideo2 {
      width: 100%;
      height: auto;
      border-radius: 0.06rem;
    }
    .blackimg {
      width: 5.2rem;
      height: 2.6rem;
    }
    .signup {
      width: 3.4rem;
      height: auto;
    }
    .butterflyhigh {
      width: 100%;
      height: auto;
    }
    .butterflyhighwj {
      width: 94%;
      height: auto;
      margin-left: 0.3%;
    }
    .butterflyhighrb {
      width: 74%;
      height: auto;
      margin-left: 22%;
      border-bottom-right-radius: 0.5rem;
      overflow: hidden;
    }
    .butterflyGif {
      width: 2rem;
      height: auto;
    }
  }
  .top1Img {
    vertical-align: middle;
    width: 100% !important;
    height: auto !important;
    visibility: visible !important;
  }
  .gifTwo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    .gifTwoImg {
      width: 48%;
      height: auto;
    }
  }
  .positionRightSignup {
    z-index: 100;
    position: fixed;
    top: 50%;
    right: 1%;
    display: inline-block;
    font-size: 0.3rem;
    color: white;
    background-color: #d6614f;
    text-align: center;
    border: none;
    border-radius: 4px;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    cursor: pointer;
    /* 添加动画效果 */
    animation: pulse 1s infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // align-content: center;
    // justify-items: center;
    font-size: 0.24rem;
    line-height: 0.3rem;
    letter-spacing: 0.03rem;
    div {
      // margin: -0.1rem 0 0;
      // padding: 0;
      // height: 0.5rem;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .girlcont {
    display: flex;
    // align-items: center;
    justify-content: center;
    width: 100%;
    .girlBg {
      display: inline-block;
      width: 59%;
      vertical-align: top;
      align-self: flex-start;
      flex: 0 0 auto;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-attachment: scroll;
      padding-right: 0.42rem;
      padding-left: 0.42rem;
      height: auto;
      background-size: 100% 100% !important;
      visibility: visible;
      background-image: url(@/assets/signUp/butterfly/girlBg.webp);
      box-sizing: border-box;
      .girlBox {
        vertical-align: middle;
        display: inline-block;
        line-height: 0;
        width: 95%;
        height: auto;
        border-radius: 99%;
        overflow: hidden;
        border-style: solid;
        border-width: 5px;
        border-color: rgb(255, 255, 255);
        visibility: visible;
        .girlImg {
          vertical-align: middle;
          width: 3.02rem !important;
          height: auto !important;
          visibility: visible !important;
        }
      }
    }
  }
  .text1 {
    text-align: center;
    color: rgb(107, 97, 167);
    font-size: 0.26rem;
    visibility: visible;
    padding: 0 0.1rem;
    box-sizing: border-box;
    .special {
      color: rgb(255, 104, 39);
    }
  }
  .text2 {
    text-align: center;
    color: rgb(107, 97, 167);
    font-size: 0.28rem;
    visibility: visible;
    font-weight: bold;
    padding: 0 0.1rem;
    box-sizing: border-box;
  }
  .text3 {
    text-align: center;
    color: rgb(170, 144, 199);
    font-size: 0.26rem;
    visibility: visible;
    text-align: right;
    padding-right: 0.2rem;
    padding: 0 0.1rem;
    box-sizing: border-box;
  }
  .text4 {
    text-align: center;
    color: rgb(107, 97, 167);
    font-size: 0.26rem;
    visibility: visible;
    text-align: center;
    padding-right: 0.2rem;
    padding: 0 0.1rem;
    box-sizing: border-box;
    font-weight: bold;
  }
  .text5 {
    text-align: center;
    color: rgb(83, 88, 119);
    font-size: 0.26rem;
    visibility: visible;
    text-align: center;
    padding-right: 0.2rem;
    padding: 0 0.1rem;
    box-sizing: border-box;
    .special {
      color: rgb(255, 104, 39);
    }
    .bold {
      font-weight: bold;
    }
  }
  .text6 {
    background-color: rgb(255, 245, 239);
    padding: 0.24rem;
    text-align: justify;
    color: rgb(170, 144, 199);
    font-size: 0.28rem;
    font-weight: bold;
    width: 96%;
    margin-left: 2%;
    border-radius: 0.02rem;
    box-sizing: border-box;
  }
  .text7 {
    text-align: center;
    color: rgb(170, 144, 199);
    font-size: 0.28rem;
    font-weight: bold;
    width: 96%;
    margin-left: 2%;
    letter-spacing: 2px;
    .italicText {
      font-style: italic;
    }
  }
  .text8 {
    display: inline-block;
    width: 88%;
    margin-right: -0.2rem;
    vertical-align: top;
    background-color: rgb(253, 248, 255);
    flex: 0 0 auto;
    height: auto;
    padding: 0 0.3rem;
    box-sizing: border-box;
    align-self: flex-start;
    color: rgb(106, 106, 106);
    font-size: 0.28rem;
    letter-spacing: 0.02rem;
    text-align: left;
    position: relative;
    .kpTitle {
      display: flex;
      align-content: center;
      padding-top: 0.2rem;
      margin-left: -0.6rem;
      .kptext {
        background: rgb(219, 197, 231);
        color: #fff;
        font-weight: bold;
        padding: 0.02rem 0.1rem;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0.1rem;
          top: 0.42rem;
          width: 0;
          height: 0;
          border-top: 0.2rem solid transparent;
          border-right: 0.2rem solid transparent;
          border-left: 0.2rem solid rgb(219, 197, 231);
          border-bottom: 0.2rem solid transparent;
          transform: rotate(-45deg);
        }
      }
      .kptext1 {
        background: rgb(170, 144, 199);
        color: #fff;
        padding: 0.02rem 0.1rem;
      }
    }
  }
  .titleName1 {
    font-size: 0.48rem;
    color: rgb(170, 144, 199);
    text-align: center;
    font-weight: bold;
  }
}
</style>
