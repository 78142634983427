<template>
  <div></div>
</template>

<script>
import urlencode from "urlencode";
export default {
  mounted() {
    this.$nextTick(() => {
      // 使用函数
      if (this.isMobileDevice()) {
        this.gotoPage();
        console.log("正在使用手机或平板等移动设备访问");
      } else {
        console.log("正在使用电脑或笔记本等桌面设备访问");
        this.$router.push({
          path: "/ProjectBridgePage/ProjectpcWindos",
        });
      }
    });
  },
  methods: {
    isMobileDevice() {
      // 正则表达式匹配常用的移动设备关键字
      return /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    gotoPage(item) {
      // this.$router.push({ path: "/aiVideo/Recording", query: { id: 1 } });
      let urlurl = urlencode(
        location.href.split("#")[0] + "#/ProjectBridgePage/ProjectGetCode?"
      );
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx959c3e8ff427eaeb&redirect_uri=" +
        urlurl +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
