<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    let that = this
    this.$nextTick(() => {
        console.log(window.location.href)
      that.wxSouquan();
    });
  },
  methods: {
    wxSouquan() {
      let urlurl = encodeURIComponent(
        "http://h5.3vppt.com/302.html"
      )
      // encodeURIComponent(window.location.href.split('#')[0]+'#/videoDownloader')
        console.log(urlurl)
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx959c3e8ff427eaeb&redirect_uri=" +
        urlurl +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>